import Home from "./components/Home"
import './App.css';

const App = () => (
  <>
    <Home />
      </>
);


export default App;
